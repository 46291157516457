export const languages = [
  { name: 'English', locale: 'en', href: 'https://knswindows.com/' },
  { name: 'Polski', locale: 'pl', href: 'https://knsokna.pl/' },
  { name: 'Deutsch', locale: 'de', href: 'https://knsfenster.de/' },
  { name: 'Čeština', locale: 'cs', href: 'https://kns-okna.eu/' },
  { name: 'Italiano', locale: 'it', href: 'https://knsfinestre.it/' },
  { name: 'Français', locale: 'fr', href: 'https://knsfenetres.fr/' },
  { name: 'Magyar', locale: 'hu', href: 'https://knsokna.eu/' },
];

export const locales = {
  EN: 'https://knswindows.com',
  PL: 'https://knsokna.pl',
  DE: 'https://knsfenster.de',
  CS: 'https://kns-okna.eu',
  IT: 'https://knsfinestre.it',
  FR: 'https://knsfenetres.fr',
  HU: 'https://knsokna.eu',
};

export const partners = [
  { name: 'veka' },
  { name: 'decco' },
  { name: 'deceuninck' },
  { name: 'reynaers' },
  // { name: 'heroal' },
  { name: 'aluron' },
  { name: 'aluprof' },
  { name: 'hueck' },
  { name: 'gealan' },
  { name: 'gu' },
  { name: 'maco' },
  { name: 'pressglass' },
  { name: 'roto' },
  { name: 'siegenia' },
  { name: 'effectglass' },
  { name: 'glassolutions' },
  { name: 'termglas' },
];
